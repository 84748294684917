"use client"
import type { Payload } from "@local/payload-client/src/types"
import { trackAction } from "@local/tracking"
import { useMemo, useRef } from "react"
import useElementObserver from "../../hooks/useElementObserver"
import { Barlow_Condensed } from "next/font/google"
import MuxPlayer from "@mux/mux-player-react"
import { TrustpilotMicroCombo } from "../TruspilotWidgets"
import HydrationContainer from "../HydrationContainer"

interface Props {
  block: Payload.VideoBlock
  trustpilotBuid?: string | null
}

const barlow = Barlow_Condensed({
  subsets: ["latin"],
  weight: ["400", "700"],
  style: ["normal"],
})

export const RemoteVideoBlockServer = ({ block, trustpilotBuid }: Props) => {
  const videoRef = useRef(null)
  const trackRef = useRef<{ unmuted: boolean; paused: boolean }>({
    unmuted: false,
    paused: false,
  })

  const title = useMemo(
    () =>
      typeof block.headline === "object"
        ? block.headline["en"]
          ? block.headline["en"]
          : block.headline
        : block.headline,
    [block]
  )

  useElementObserver({
    elementRef: videoRef,
    observeIntersection: true,
  })

  const trackVideoAction = (action: "unmuted" | "paused") => {
    trackAction("video_user_interaction", { action })
    trackRef.current[action] = true
  }

  return (
    <section
      id="lp-remote-video"
      className="px-4 "
      style={{
        backgroundColor: block.backgroundColor!,
        color: block.textColor!,
      }}
    >
      <div className="flex flex-col items-center px-4 py-12 mx-auto sm:py-12 sm:px-6 lg:px-24">
        <h2
          className="max-w-5xl mb-6 text-5xl text-center uppercase sm:text-6xl"
          dangerouslySetInnerHTML={{ __html: title }}
          style={barlow.style}
        />
        <HydrationContainer>
          <div ref={videoRef} className="w-full max-w-6xl mb-4 aspect-video">
            <MuxPlayer
              autoPlay
              defaultHiddenCaptions={false}
              loop
              metadataVideoTitle={title}
              muted
              nohotkeys
              playbackId={block.videoId}
              streamType="on-demand"
              className="w-full"
              maxResolution="720p"
              onPause={() =>
                !trackRef?.current["paused"] && trackVideoAction("paused")
              }
              onVolumeChange={() =>
                !trackRef?.current["unmuted"] && trackVideoAction("unmuted")
              }
            />
            <style>
              {`
              mux-player {
                --seek-backward-button: none;
                --pip-button: none;
                --seek-forward-button: none;
                --rendition-selectmenu: none;
                --playback-rate-button: none;
                --time-range: none;
                --volume-range: none;
                --media-object-fit: cover;
                inset: 0;
                z-index: 5;
              }
              mux-player::part(gesture-layer) {
                display: none;
              }
            `}
            </style>
          </div>
        </HydrationContainer>
        <div className="w-full -mb-6 text-center">
          <p className="-mb-4 text-xl font-semibold sm:text-2xl">
            Over 9000 5-Star Reviews
          </p>
          <HydrationContainer>
            <TrustpilotMicroCombo buid={trustpilotBuid} theme="dark" />
          </HydrationContainer>
        </div>
      </div>
    </section>
  )
}

export default RemoteVideoBlockServer
