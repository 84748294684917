import { useEffect } from "react"
import { trackAction } from "@local/tracking"

interface UseMultiObserverParams {
  elementRef: React.RefObject<Element>
  observeIntersection?: boolean
}

const useElementObserver = ({
  elementRef,
  observeIntersection,
}: UseMultiObserverParams): void => {
  useEffect(() => {
    const element = elementRef?.current
    if (!element) {
      return
    }

    const cleanups: Array<() => void> = [] // Gather cleanup functions to handle multiple observers

    // Intersection Observer Logic
    if (observeIntersection) {
      const viewThreshold = 1.0
      let observer: IntersectionObserver
      let enterTimestamp: number | undefined
      let exitTimestamp: number | undefined

      const handleIntersect: IntersectionObserverCallback = (entries) => {
        entries.forEach((entry) => {
          if (
            entry.intersectionRatio === viewThreshold &&
            enterTimestamp === undefined
          ) {
            enterTimestamp = Date.now()
          } else if (
            entry.intersectionRatio < 0.5 &&
            enterTimestamp !== undefined &&
            exitTimestamp === undefined
          ) {
            exitTimestamp = Date.now()
            trackAction("video_view_duration", {
              initialTimestamp: enterTimestamp,
              finalTimestamp: exitTimestamp,
            })
            observer.disconnect()
          }
        })
      }

      observer = new IntersectionObserver(handleIntersect, {
        threshold: [0, viewThreshold],
      })
      observer.observe(element)

      cleanups.push(() => observer.disconnect())
    }

    return () => {
      cleanups.forEach((cleanup) => cleanup())
    }
  }, [elementRef, observeIntersection])
}

export default useElementObserver
