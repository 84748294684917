import Cookies from "js-cookie"
import { usePathname, useSearchParams } from "next/navigation"
import CallToAction, { getInitialCtaString } from "../CallToAction"
import { Suspense } from "react"
import type { Payload } from "@local/payload-client/src/types"
import { isBrowser, rebuildUrlParams } from "@local/utils"
import HydrationContainer from "../HydrationContainer"

interface Props {
  bgColor: Payload.Anchor["buttonBgColor"]
  callToAction: string
  isMirror?: boolean
  isMulti?: boolean
  noDiscount?: boolean
  slug?: string | null
  textColor: Payload.Anchor["buttonTextColor"]
}

const BuyButtonHighlights = ({
  bgColor,
  callToAction,
  isMirror,
  isMulti,
  noDiscount,
  slug,
  textColor,
}: Props) => {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const isB2BPage = pathname?.includes("business")
  const multiUrlPrefix = isMulti ? `/lp/${slug}` : ""

  return (
    <a
      href={
        isMirror
          ? noDiscount
            ? `https://baerskintactical.com${rebuildUrlParams(searchParams, `/lp/${slug}/checkout/x/`)}`
            : `https://baerskintactical.com${rebuildUrlParams(searchParams, `/lp/${slug}/checkout/`)}`
          : isMulti
            ? noDiscount
              ? `${multiUrlPrefix}/x/checkout/`
              : `${multiUrlPrefix}/checkout/`
            : isB2BPage
              ? "https://ask.baerskinhoodie.com/business"
              : noDiscount
                ? "/x/checkout/"
                : "/checkout/"
      }
      onClick={() => Cookies.remove("show-upsell-modal")}
      className="transition-colors duration-500 hover:no-underline mx-auto my-4 cursor-pointer block w-full min-w-[75%] rounded-lg px-2 py-4 text-center text-xl font-bold uppercase sm:inline-block sm:w-auto md:w-full sm:px-8 sm:text-2xl lg:min-w-full xl:min-w-[75%] hover:opacity-75"
      style={{
        backgroundColor: bgColor,
        color: textColor,
      }}
    >
      <HydrationContainer fallback={<>{getInitialCtaString()}</>}>
        <CallToAction
          input={noDiscount ? null : callToAction}
          action="Select Size Now"
          truncate
        />
      </HydrationContainer>
    </a>
  )
}

export default BuyButtonHighlights
