"use client"
import { useMemo, useState } from "react"
import Image from "next/image"
import { Avatar } from "../Avatar"
import styles from "./styles.module.css"
import { trackAction } from "@local/tracking/src/trackActions"
import { type RatingScores, Stars } from "./Stars"
import { BunnyLoader } from "@local/utils/src/bunnyLoader"
import { GET_ANY_CHANNEL_ID } from "../../const"
import { Payload } from "@local/payload-client/src/types"

interface ReviewItemProps {
  date?: string
  id: string
  image?: string | null
  name: string
  stars: number
  text: string
  channel?: Payload.AnchorChannelId
}

export const ReviewItem = ({
  date,
  id,
  image,
  name,
  stars,
  text,
  channel,
}: ReviewItemProps) => {
  const { IS_BAERSKIN_ONLY } = GET_ANY_CHANNEL_ID(channel)
  const [isOpen, setIsOpen] = useState(false)

  const content = useMemo(() => {
    if (isOpen) {
      return text
    }
    const words = text.split(" ")
    let final = ""
    for (let i = 0; i < 6; i++) {
      if (words[i]) {
        final += " " + words[i]
      }
    }
    final += "..."
    return final
  }, [isOpen, text])

  const openReview = () => {
    setIsOpen(true)
    trackAction("review_clicked", {
      rating: stars,
      review_id: id,
    })
  }

  return (
    <article className={styles.review_block}>
      <div className={styles.review_block__header}>
        <div className={styles.review_block__header__top}>
          <Avatar>{name.charAt(0)}</Avatar>
          <Stars
            rating={stars as RatingScores}
            overrideColor={IS_BAERSKIN_ONLY ? "#00b679" : undefined}
          />
        </div>
        <div className={styles.review_block__header__bottom}>
          <h4>{name}</h4>
          <p>Verified Buyer</p>
        </div>
      </div>
      <div className={styles.review_block__content}>
        {image && (
          <Image
            alt="person"
            className={styles.Section_image}
            src={image}
            loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined}
          />
        )}
        {content}{" "}
        {!isOpen && (
          <p>
            <button
              id={`review_btn_${id}`}
              className={styles.read_more}
              onClick={openReview}
            >
              Read More
            </button>
          </p>
        )}
        {date && <p>{new Date(date).toLocaleDateString("en-US")}</p>}
      </div>
    </article>
  )
}
