import type { ReactNode } from "react"
import { classNames } from "@local/utils/src/classNames"

interface Props {
  children: ReactNode
  size?: 8 | 12
  background?: "gray" | "white"
  opacity?: 0 | 25 | 50 | 75 | 100
}

const Avatar = ({ background, children, opacity, size }: Props) => (
  <span
    className={classNames(
      "inline-flex items-center justify-center overflow-hidden rounded-full align-middle",
      background && background === "white"
        ? "bg-white text-black"
        : "bg-gray-500 text-white",
      size && size === 8 ? "h-8 w-8 text-sm" : "h-12 w-12 text-lg",
      opacity ? `opacity-${opacity}` : ""
    )}
  >
    <span className="font-bold leading-none">{children}</span>
  </span>
)

export { Avatar }
