"use client"
import { Suspense, useId, useMemo, useRef, useState } from "react"
import Image from "next/image"
import { Checkmark } from "../Checkmark"
import { CreditCards } from "../CreditCards"
import { StarsWithScore } from "../Reviews/StarsWithScore"
import Shipping from "./images/shipping.svg"
import ShippingHam from "./images/shipping-ham.svg"
import ShippingBsh from "./images/shipping-bsh.svg"
import styles from "./styles.module.css"
import { getDiscountPrice } from "@local/utils/src/getDiscountPrice"
import { GET_ANY_CHANNEL_ID, HAM_AFFILIATE_PAGE_ID } from "../../const"
import { clearDecimals, isBrowser } from "@local/utils"
import type { Payload } from "@local/payload-client/src/types"
import { Price, useI10n, type Currency } from "@local/i10n"
import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/navigation"
import "swiper/css/thumbs"
import { Carousel, type SwiperRef } from "../Carousel"
import { createPortal } from "react-dom"
import { Modal } from "@local/ui"
import { useSearchParams } from "next/navigation"
import { BunnyLoader } from "@local/utils/src/bunnyLoader"
// import { InstallmentsMessage } from "../InstallmentsMessage"
import useIsMobile from "../../useIsMobile"
import BuyButtonHighlights from "./BuyButtonHighlights"
import { TrustpilotMicroCombo } from "../TruspilotWidgets"

interface Props {
  block: Payload.HighlightBlock // data
  channel?: Payload.AnchorChannelId
  isMirror?: boolean
  isMulti?: boolean
  noDiscount?: boolean
  page: Payload.Anchor
  slug?: string | null
  trustpilotBuid?: string | null
}

export const Highlights = ({
  block,
  channel,
  isMirror,
  isMulti,
  noDiscount,
  page,
  slug,
  trustpilotBuid,
}: Props) => {
  const { IS_BAERSKIN, IS_HAM, IS_NOBSTEES } = GET_ANY_CHANNEL_ID(channel)
  const isMobile = useIsMobile()

  const { country, currencyCode } = useI10n()
  const [openModal, setOpenModal] = useState(false)
  const id = useId()

  const searchParams = useSearchParams()

  const carouselRef = useRef<SwiperRef>(null)
  const modalCarouselRef = useRef<SwiperRef>(null)

  const products = page.product[0]

  // This is needed to make sure we can use specific URL to avoid feed disapprovals
  const slides = useMemo(() => {
    const data = block.slides
    const itemId = searchParams?.get("item_id")

    if (itemId && block.showPrice) {
      const variant = products.variants?.find((item) => item.id === itemId)

      if (variant && variant.image?.url) {
        const alreadyHasImage = data?.find(
          (image) => variant?.image?.url === image.image.url
        )
        if (alreadyHasImage) {
          return data
        }

        data?.unshift({
          title: itemId,
          id: itemId,
          image: variant.image,
        })
      }
    }

    return data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const quoteCountry =
    country === "gb"
      ? "UK"
      : country === "eu"
        ? "Germany"
        : country === "us"
          ? "USA"
          : country.toUpperCase()

  const { comparePrice, discountPrice, discount } = useMemo(() => {
    // V3 Prices
    if (page.checkout === "v3" && page.tiersv3) {
      // const tiers = page.tiersv3.sort(
      //   (a, b) => a.totalQuantity - b.totalQuantity
      // )
      //const tier = tiers[tiers.length - 1]
      let currentPriceValue = products.origPrice

      if (currencyCode && currencyCode !== "USD" && products.prices) {
        const selectedPrice = products.origPrices?.find(
          (item) => item.currency === currencyCode
        )?.price

        currentPriceValue =
          typeof selectedPrice === "number" ? selectedPrice : currentPriceValue
      }

      // const items = tier.totalQuantity
      // Changed here so it shows the "highest price now"
      // Part of DV-3159 request
      const items = 1

      const tierPrices = products.prices
        .sort((a, b) => a.minQuantity - b.minQuantity)
        .find((item) => item.minQuantity >= items)

      const tierPrice =
        currencyCode !== "USD"
          ? (tierPrices?.[currencyCode!] as number)
          : (tierPrices?.price as number)

      const discount = clearDecimals(
        ((currentPriceValue - tierPrice) / currentPriceValue) * 100,
        0
      )

      return {
        comparePrice: currentPriceValue,
        discountPrice: tierPrice,
        discount,
      }
    }

    /// Legacy Prices
    const pricedComparePrice =
      (products &&
        products?.hasOwnProperty("origPrices") &&
        products.origPrices?.find(
          (p) => (p.currency as unknown as Currency) === currencyCode
        )?.price) ||
      null
    const comparePrice =
      products && currencyCode !== "USD" && pricedComparePrice
        ? pricedComparePrice
        : (products && products.origPrice) || null

    const discountPrice =
      products && products.origPrice
        ? getDiscountPrice(products, currencyCode)
        : null

    const discount =
      discountPrice && comparePrice
        ? Math.round(100 - (Number(discountPrice) / Number(comparePrice)) * 100)
        : null

    return {
      comparePrice,
      discountPrice,
      discount,
    }
  }, [currencyCode, page, products])

  const reviews = products.reviews?.length

  return (
    <Suspense>
      <div id={block.anchor as string} className={styles.Highlights}>
        <div>
          <div className={styles.Highlights__Wrapper__Title_mobile}>
            <h2>{block.title}</h2>
          </div>
          <Carousel
            slides={slides}
            onClick={() => {
              !isMobile ? setOpenModal(!openModal) : null
            }}
            thumbsAmount={5}
            refObj={carouselRef}
          />
        </div>
        <div className={styles.Highlights__Wrapper}>
          <h2 className={styles.Highlights__Wrapper__Title_desktop}>
            {block.title}
          </h2>
          {/* ONLY FOR HAM AFFILIATE PAGE */}
          {page.id === HAM_AFFILIATE_PAGE_ID && (
            <StarsWithScore rating={4.5} reviews={reviews || 13} />
          )}
          {block.subheader && <p>{block.subheader}</p>}
          {block.quoteTitle && block.quoteText && (
            <blockquote className={styles.Highlights__Wrapper__Quote}>
              <p className={styles.Highlights__Wrapper__Quote__Text}>
                &ldquo;{block.quoteText}&rdquo;
              </p>
              <p className={styles.Highlights__Wrapper__Quote__Author}>
                - {block.quoteTitle}, {quoteCountry}
              </p>
              {block.trustpilot && page.trustpilot ? (
                <TrustpilotMicroCombo
                  price={discountPrice}
                  buid={trustpilotBuid}
                />
              ) : block.reviewCount ? (
                <StarsWithScore
                  rating={4.5}
                  reviews={block.reviewCount || 13}
                />
              ) : (
                ""
              )}
            </blockquote>
          )}
          {block.list && (
            <ul>
              {block.list.map((item) => (
                <li key={item?.id}>
                  <span
                    style={
                      page.topBarBgColor
                        ? {
                            color: IS_BAERSKIN
                              ? "#4B7F0D"
                              : IS_HAM
                                ? "#3984DA"
                                : page.topBarBgColor,
                          }
                        : {}
                    }
                  >
                    <Checkmark />
                  </span>
                  {item?.title && (
                    <strong className={styles.Highlights__Wrapper__ListItem}>
                      {item.title}:
                    </strong>
                  )}{" "}
                  {item?.text}
                </li>
              ))}
            </ul>
          )}
          {!block.showPrice ? (
            <div className={styles.Highlights__BuyButtonWrapper}>
              <Suspense>
                <BuyButtonHighlights
                  bgColor={page.buttonBgColor}
                  callToAction={page.callToAction}
                  isMirror={isMirror}
                  isMulti={isMulti}
                  noDiscount={noDiscount}
                  slug={slug}
                  textColor={page.buttonTextColor}
                />
              </Suspense>
            </div>
          ) : (
            <div className="relative">
              {!noDiscount && page.badge && (
                <div className={styles.PromoEvent}>
                  <Image
                    alt="Promo"
                    height={(page.badge as Payload.Media).height}
                    src={(page.badge as Payload.Media).url}
                    width={(page.badge as Payload.Media).width}
                  />
                </div>
              )}
              <span id="pricecta" className="absolute -mt-[30vh]" />
              <div className="my-4 text-center">
                {!noDiscount &&
                  products &&
                  comparePrice &&
                  discount &&
                  discount > 0 && (
                    <p className="text-3xl text-[#BFBFBF] line-through">
                      <Price value={comparePrice * 100} />
                    </p>
                  )}
                {discountPrice && discount && (
                  <>
                    <p className="text-5xl text-[#17A34A]  font-semibold">
                      <Price value={discountPrice * 100} />
                    </p>
                    {!noDiscount && (
                      <p className="text-xl uppercase text-[#17A34A] font-semibold">
                        {discount > 0 && !IS_NOBSTEES && <>Save {discount}% </>}
                      </p>
                    )}
                  </>
                )}
              </div>

              {/* {discountPrice && currencyCode && (
                <InstallmentsMessage price={Math.round(discountPrice * 100)} />
              )} */}

              <div className={styles.Highlights__BuyButtonWrapper}>
                <Suspense>
                  <BuyButtonHighlights
                    bgColor={page.buttonBgColor}
                    callToAction={page.callToAction}
                    isMirror={isMirror}
                    isMulti={isMulti}
                    noDiscount={noDiscount}
                    slug={slug}
                    textColor={page.buttonTextColor}
                  />
                </Suspense>
              </div>
            </div>
          )}
          {block.showShippingInformation && (
            <div className={styles.Highlights__Shipping}>
              <Image
                alt="Secure Order, 100% Quality, 100% Premium, Free Shipping"
                height={130}
                src={
                  IS_BAERSKIN ? ShippingBsh : IS_HAM ? ShippingHam : Shipping
                }
                width={600}
              />
            </div>
          )}
          {block.showCreditCards && <CreditCards />}
        </div>

        {openModal &&
          createPortal(
            <Modal open={true} toggle={() => setOpenModal(false)}>
              <div className="relative w-full h-full pt-4 overflow-hidden md:pt-6">
                {slides ? (
                  slides.length > 1 ? (
                    <Carousel
                      refObj={modalCarouselRef}
                      key={`carousel-highlight-${id}-${slides.length}`}
                      slides={slides}
                      thumbsAmount={7}
                      startingSlide={
                        carouselRef.current?.swiper?.realIndex ?? 0
                      }
                    />
                  ) : (
                    <Image
                      src={slides[0].image.url}
                      alt={""}
                      width={400}
                      height={400}
                      loader={
                        process.env.NEXT_PUBLIC_BUNNY_URL
                          ? BunnyLoader
                          : undefined
                      }
                    />
                  )
                ) : null}
              </div>
              &nbsp;
            </Modal>,
            document.body
          )}
      </div>
    </Suspense>
  )
}

export default Highlights
