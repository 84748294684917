"use client"
import { useMemo, useState } from "react"
import styles from "./styles.module.css"
import type { Payload } from "@local/payload-client/src/types"
import { StarsWithScore } from "./StarsWithScore"
import { ReviewItem } from "./Item"
import type { RatingScores } from "./Stars"

interface ReviewSectionProps {
  block: Payload.ReviewBlock
  channel?: Payload.AnchorChannelId
}

export const ReviewsBlock = ({ block, channel }: ReviewSectionProps) => {
  const [amountToDisplay, setAmount] = useState(8)

  // const reviews = useMemo(() => {
  //   console.log(block.reviews)

  //   if (country === "de") {
  //     return block.reviews.filter((item) => item.country.toLowerCase() === "de")
  //   }

  //   if (country === "hk" || country === "tw") {
  //     return block.reviews.filter((item) =>
  //       ["hk", "tw"].includes(item.country.toLowerCase())
  //     )
  //   }

  //   if (country === "ae" || country === "sa") {
  //     return block.reviews.filter(
  //       (item) => item.country.toLowerCase() === "uae"
  //     )
  //   }

  //   return block.reviews.filter((item) => item.country.toLowerCase() === "us")
  // }, [block, country])

  const reviews = useMemo(
    () => block.reviews?.slice(0, amountToDisplay),
    [amountToDisplay, block]
  )

  const stars = useMemo(() => {
    let totalStars = 0
    reviews?.forEach((item) => {
      totalStars += item.rating as number
    })
    return Number((totalStars / (reviews?.length || 1)).toFixed(1))
  }, [reviews])

  const rating = (Math.round(((stars ?? 45) + Number.EPSILON) * 100) /
    100) as RatingScores

  return (
    <section
      id={block.anchor ?? "reviews"}
      className={styles.review_section}
      style={{
        backgroundColor: block.backgroundColor,
        color: block.textColor,
      }}
    >
      <header className={styles.review_section__header}>
        <h3>
          {/* <TrackableElement
            elementIdentifier="Section - title"
            trackView={true}
            content={reviewTitle}
            retentionTrackingTime={3000}
          > */}
          {block.title}
          {/* </TrackableElement> */}
        </h3>
        <StarsWithScore
          rating={rating}
          reviews={block.reviews?.length}
          vertical
          displayRating
        />
      </header>
      <div className={styles.review_section__content}>
        {reviews?.map((item, i) => (
          <ReviewItem
            // date={item?.date as string}
            id={`reviews_${i + 1}`}
            // image={item?.image ? (item?.image?.file?.url as string) : null}
            key={`${item?.id}-${i}`}
            name={item?.name as string}
            stars={item?.rating as number}
            text={item?.content as string}
            channel={channel}
          />
        ))}
      </div>
      {block.reviews && amountToDisplay < block.reviews.length && (
        <p className="text-center">
          <button
            className="px-6 py-4 mx-auto text-lg text-center text-white bg-black hover:opacity-80"
            onClick={() => {
              setAmount((a) => a + 8)
            }}
          >
            Read More
          </button>
        </p>
      )}
    </section>
  )
}
