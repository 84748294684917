"use client"
import { useState } from "react"
import { Transition } from "@local/ui/src/Transition"
import { classNames } from "@local/utils/src/classNames"
import styles from "./styles.module.css"
import { trackAction } from "@local/tracking/src/trackActions"
import { useI10n } from "@local/i10n"

const ChevronDownIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    aria-hidden="true"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
    />
  </svg>
)

interface FaqItemProps {
  title: string
  text: string
}

export const FaqItem = ({ title, text }: FaqItemProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { currencySymbol } = useI10n()

  const toggleItem = () => {
    const verb = isOpen ? "closed" : "opened"
    trackAction(`faq_${verb}`, {
      title,
      text,
    })
    setIsOpen(!isOpen)
  }

  const answer =
    typeof text === "object"
      ? (text as any)["en"]?.replace("%%CURRENCY%%", `${currencySymbol}\u2060`)
      : text?.replace("%%CURRENCY%%", `${currencySymbol}\u2060`)

  return (
    <div className={styles.disclosure}>
      <dt className="text-lg">
        <button className={styles.button} onClick={toggleItem}>
          <strong>{title}</strong>
          <span className={styles.chevron}>
            <ChevronDownIcon
              className={classNames(
                isOpen ? styles.chevron__open : styles.chevron__close,
                styles.chevron__base
              )}
              aria-hidden="true"
            />
          </span>
        </button>
      </dt>
      <Transition
        show={isOpen}
        enter={styles.transition_enter}
        enterFrom={styles.transition_enterFrom}
        enterTo={styles.transition_enterTo}
        leave={styles.transition_leave}
        leaveFrom={styles.transition_leaveFrom}
        leaveTo={styles.transition_leaveTo}
      >
        <dd
          className="content-block"
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      </Transition>
    </div>
  )
}
