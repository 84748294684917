import { Currency } from "@local/i10n"
import type { Payload } from "@local/payload-client/src/types"
import {
  getCurrency,
  getFullPrice,
} from "@local/product-option-manager/utils/pricing"

export const getDiscountPrice = (
  product: Payload.Product,
  currency?: Currency | null
) => {
  const discountPrices =
    product?.prices &&
    product?.prices?.length > 0 &&
    (product?.prices).sort(
      (a, b) => Number(a.minQuantity) - Number(b.minQuantity)
    )[0]

  if (discountPrices) {
    const discountPrice =
      // If USD then return base price
      currency && currency !== "USD" && discountPrices.hasOwnProperty(currency)
        ? discountPrices[currency]
        : discountPrices.price

    return discountPrice
  }

  return currency
    ? getFullPrice(product, getCurrency(currency))
    : product?.origPrice
}
