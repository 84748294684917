"use client"
import styles from "./styles.module.css"
import type { Payload } from "@local/payload-client/src/types"
import { FaqSchemaJsonLd } from "@local/utils/src/jsonLdSchemas/FAQ"
import { FaqItem } from "./Item"
import { useI10n } from "@local/i10n/src/useI10n"

interface Props {
  block: Payload.FaqBlock
}

type TFaqItem = {
  a: string
  q: string
}

export const Faq = ({ block }: Props) => {
  const { isUSA } = useI10n()
  const faqs: TFaqItem[] = []

  block.faq?.forEach((item) => {
    faqs.push({
      a: item.answer,
      q: item.question,
    } as TFaqItem)
  })

  if (process.env.NEXT_PUBLIC_ENABLE_KLARNA === "true" && isUSA()) {
    faqs.push({
      q: "Can I buy now and pay for my purchase later?",
      a: 'Yes, you can! With Klarna payment solutions, you can choose whether you would like to pay for your order now, pay within 30 days or to pay in 4 interest free instalments. <a href="https://www.klarna.com/us/customer-service/" target="_blank" rel="noopener noreferrer" aria-label="Redirect to klarna customer service in a new tab">Contact Klarna customer support here</a>.',
    })
    faqs.push({
      q: "What is Klarna?",
      a: '<a href="https://www.klarna.com/us/" target="_blank" rel="noreferrer noopener" aria-label="Redirect to klarna in a new tab">Klarna</a> is the smoothest & safest way to get what you want today, and pay over time.',
    })
    faqs.push({
      q: "Questions about Klarna?",
      a: 'Check out Klarna’s full FAQ page. You can reach Klarna anytime at <a href="https://www.klarna.com/us/customer-service/" target="_blank" rel="noreferrer noopener" aria-label="Redirect to klarna customer service in a new tab">https://www.klarna.com/us/customer-service/</a> or by downloading the Klarna app.',
    })
  }

  return (
    <section
      id="faq"
      className={styles.section}
      style={{
        backgroundColor: block.backgroundColor,
        color: block.textColor,
      }}
    >
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h2>{block.title}</h2>
          <dl>
            {faqs.map((faq, i) => (
              <FaqItem key={`${faq.q}-${i}`} title={faq.q} text={faq.a} />
            ))}
          </dl>
        </div>
      </div>
      <FaqSchemaJsonLd item={block.faq} />
    </section>
  )
}
