"use client"

import { type Payload } from "@local/payload-client/src/types"

const SenjaReviews = ({
  block,
  senjaId,
}: {
  block: Payload.SenjaReviewsBlock
  senjaId?: string | null
}) => {
  if (!senjaId) return

  return (
    <div id="reviews">
      <section className="w-full py-10 mb-4 text-gray-900 content-block">
        <h2 className="px-8 mb-6 text-3xl font-bold text-center lg:text-3xl">
          {block.headline.length > 1
            ? block.headline
            : "What Our Customers Are Saying"}
        </h2>
        <div className="senja-embed" data-id={senjaId} data-lazyload="false" />
      </section>
    </div>
  )
}

export default SenjaReviews
