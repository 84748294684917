import { classNames } from "@local/utils/src/classNames"

interface Props {
  className?: string
  height?: number
  width?: number
  solid?: boolean
}

export const Checkmark = ({ className, height, width, solid }: Props) =>
  solid ? (
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 97.5 97.5"
      height={height || "1em"}
      width={width || "1em"}
      className={classNames(
        "mr-1 inline-block -translate-y-0.5 transform fill-gray-800",
        className || ""
      )}
    >
      <g
        className="fill-arrow"
        fillRule="evenodd"
        fill="currentColor"
        aria-hidden="true"
      >
        <path d="M48.8 0C21.9 0 0 21.9 0 48.8s21.9 48.8 48.8 48.8 48.8-21.9 48.8-48.8S75.6 0 48.8 0zm-9 73.2L21 52.2l5.6-5 13.1 14.6 31.1-37.1 5.8 4.8-36.8 43.7z" />
      </g>
    </svg>
  ) : (
    <svg
      aria-hidden="true"
      height={height || 24}
      viewBox="0 0 30 30"
      width={width || 24}
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(
        "mr-1 inline-block -translate-y-0.5 transform fill-gray-800",
        className || ""
      )}
    >
      <g className="fill-arrow" fillRule="evenodd" fill="currentColor">
        <path d="m15 28.125a13.125 13.125 0 1 0 -13.125-13.125 13.125 13.125 0 0 0 13.125 13.125zm0 1.875a15 15 0 1 0 -15-15 15 15 0 0 0 15 15z" />
        <path
          d="m22.1 11.1a1.406 1.406 0 1 1 2.009 1.969l-7.485 9.356a1.407 1.407 0 0 1 -2.025.038l-4.961-4.963a1.407 1.407 0 1 1 1.988-1.988l3.927 3.924 6.512-8.3a.442.442 0 0 1 .035-.036z"
          transform="translate(-1.53 -1.779)"
        />
      </g>
    </svg>
  )

export const CheckIcon = ({ className }: Props) => (
  <svg
    aria-hidden="true"
    width="54"
    height="58"
    viewBox="0 0 54 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g filter="url(#filter0_d_780_294)">
      <path
        d="M43.8677 12.9005L30.8145 6.79014C28.4986 5.73662 25.551 5.73662 23.2351 6.79014L10.182 12.9005C7.23448 14.3754 5.55018 17.7467 5.97126 21.1179C7.02395 28.0711 10.3925 41.1347 22.3931 50.827C23.8669 51.8806 25.551 52.3019 27.0249 52.3019C28.4986 52.3019 30.1829 51.6699 31.4461 50.6163C43.4467 40.924 47.0257 27.8604 47.8679 20.9072C48.4995 17.7467 46.8152 14.3754 43.8677 12.9005Z"
        fill="#05A067"
      />
      <path
        d="M25.9012 35.8858C25.2698 35.8858 24.6384 35.6753 24.2175 35.0439L18.5349 28.0986C17.6931 27.2568 17.9036 25.7835 18.7454 25.1521C19.5873 24.3103 21.0605 24.5207 21.6919 25.3626L25.9012 30.4137L34.7406 19.6801C35.5825 18.8382 36.8453 18.6278 37.6871 19.4696C38.529 20.3115 38.7394 21.5743 37.8976 22.4161L27.3744 35.0439C27.1639 35.6753 26.5326 35.8858 25.9012 35.8858Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_780_294"
        x="0.639534"
        y="0.732308"
        width="52.6282"
        height="56.8373"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.63385" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_780_294"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_780_294"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
