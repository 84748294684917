import { Swiper, type SwiperClass, SwiperSlide } from "swiper/react"
import { Navigation, Thumbs } from "swiper/modules"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/thumbs"
import type SwiperCore from "swiper"
import { type Dispatch, type SetStateAction, useState, useId } from "react"
import Image from "next/image"
import type { Payload } from "@local/payload-client/src/types"
import { BunnyLoader } from "@local/utils/src/bunnyLoader"

export interface SwiperRef extends React.HTMLAttributes<HTMLElement> {
  swiper: SwiperClass
}

interface CarouselProps {
  slides:
    | {
        title?: string | undefined
        image: Payload.Media
        id?: string | undefined
      }[]
    | undefined
  dir?: "rtl" | "ltr"
  onClick?: Dispatch<SetStateAction<boolean>>
  thumbsAmount?: number
  startingSlide?: number
  refObj?: React.RefObject<SwiperRef>
}

export const Carousel = ({
  slides,
  dir,
  onClick,
  thumbsAmount = 5,
  startingSlide,
  refObj,
}: CarouselProps) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore>()
  const uid = useId()

  const middlePoint = Math.floor(thumbsAmount / 2)

  return (
    <>
      <Swiper
        ref={refObj}
        initialSlide={startingSlide ?? 0}
        dir={dir ?? "ltr"}
        style={{
          // @ts-ignore-next-line
          "--swiper-navigation-color": "#AAAAAA",
          "touchAction": "auto",
        }}
        loop={true}
        spaceBetween={10}
        navigation={true}
        grabCursor={true}
        thumbs={{
          swiper:
            // hack to fix the bug where the thumbs swiper is not defined
            // https://github.com/nolimits4web/swiper/issues/5630
            thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[Navigation, Thumbs]}
        className="w-full pb-10 mb-4 aspect-square"
        onClick={() => {
          onClick && onClick(true)
        }}
        onSlideChange={(e) => {
          thumbsSwiper?.slideTo(e.realIndex - middlePoint)
        }}
        // }}
      >
        {slides?.map((slide) => (
          <SwiperSlide key={`${uid}-${slide?.image.id}-swiper-slide`}>
            <Image
              src={slide?.image.url}
              alt={slide?.image.alt ?? `Product image ${slide.title}`}
              width={1500}
              height={1500}
              loading="lazy"
              loader={
                process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined
              }
            />
            <div className="swiper-lazy-preloader swiper-lazy-preloader-black"></div>
          </SwiperSlide>
        ))}
      </Swiper>
      {slides?.length && slides.length > 1 && (
        <Swiper
          dir={dir}
          key={`uniquej-${uid}`}
          onSwiper={setThumbsSwiper}
          slidesPerView={thumbsAmount}
          spaceBetween={0}
          modules={[Thumbs]}
          onClick={(e) => {
            thumbsSwiper?.slideToLoop(e.clickedIndex - middlePoint)
          }}
        >
          {slides.map((slide) => (
            <div key={`${uid}-${slide?.image.id}-thumb-container`}>
              <SwiperSlide
                key={`${uid}-${slide?.image.id}-thumb`}
                className="cursor-grab active:cursor-grabbing"
              >
                <Image
                  key={`${uid}-${slide?.image.id}-thumb-image`}
                  src={slide?.image.url}
                  alt={slide?.image.alt ?? `Product image ${slide.title}`}
                  width={150}
                  height={150}
                  loading={"eager"}
                  loader={
                    process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined
                  }
                  priority
                />
              </SwiperSlide>
            </div>
          ))}
          {slides.length > thumbsAmount && (
            <div key={`${uid}-last-slide-thumb-container`}>
              <SwiperSlide
                key={`${uid}-last-slide-thumb`}
                className="cursor-grab active:cursor-grabbing"
                onClick={() => {
                  thumbsSwiper?.slideToLoop(0)
                  refObj?.current?.swiper.slideToLoop(0)
                }}
              >
                <div className="swiper-zoom-container">
                  <Image
                    key={`${uid}-last-slide-thumb-image`}
                    alt={
                      slides[0]?.image.alt ?? `Product image ${slides[0].title}`
                    }
                    src={slides[0]?.image.url}
                    width={150}
                    height={150}
                    loading={"eager"}
                    loader={
                      process.env.NEXT_PUBLIC_BUNNY_URL
                        ? BunnyLoader
                        : undefined
                    }
                    priority
                  />
                </div>
              </SwiperSlide>
            </div>
          )}
        </Swiper>
      )}
    </>
  )
}
