"use client"
import { classNames } from "@local/utils/src/classNames"
import { AnchorScroll } from "../AnchorScroll"
import { Stars, type StarsProps } from "./Stars"

interface StarsWithScoreProps extends StarsProps {
  displayRating?: boolean
  reviews?: number
  vertical?: boolean
}

export const StarsWithScore = ({
  displayRating,
  rating,
  reviews,
  vertical,
}: StarsWithScoreProps) => {
  return (
    <div
      className={classNames(
        vertical
          ? "flex-col items-center justify-center space-y-2"
          : "flex-row items-start justify-start space-x-2",
        "my-2 flex flex-row align-middle"
      )}
      data-testid="StarsWithScore"
    >
      <Stars rating={rating} />
      {displayRating && (
        <p className="uppercase text-yellow-400">{rating} of 5</p>
      )}
      {reviews && (
        <p className="cursor-pointer text-sm hover:underline">
          <AnchorScroll to="reviews">{reviews} Customer Reviews</AnchorScroll>
        </p>
      )}
    </div>
  )
}
